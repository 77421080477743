type ContactFormFieldsProps = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  company: string
  numberOfEmployees: string
  country: string
  title: string
  message: string
  interestedIn: string
}

type PreparedDataProps = {
  oid: string
  lead_source: string
  first_name: string
  last_name: string
  email: string
  mobile: string
  company: string
  employees: string
  country: string
  title: string
  description?: string
  //interested in
  '00N0900000B3zcS'?: string
}

export const prepareData = (
  data: ContactFormFieldsProps
): PreparedDataProps => {
  return {
    oid: '00D09000006IhlV', //Put your ORGID here
    lead_source: 'Website',
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    mobile: data.phoneNumber,
    company: data.company,
    employees: data.numberOfEmployees,
    country: data.country,
    title: data.title,
    description: data.message,
    '00N0900000B3zcS': data.interestedIn,
  }
}

export default (fields: PreparedDataProps) => {
  const customHiddenIframeName = 'JLA_API'
  if (!document.getElementById(customHiddenIframeName)) {
    const theiFrame = document.createElement('iframe')
    theiFrame.id = customHiddenIframeName
    theiFrame.name = customHiddenIframeName
    theiFrame.src = 'about:blank'
    theiFrame.style.display = 'none'
    document.body.appendChild(theiFrame)
  }
  const form = document.createElement('form')
  form.method = 'POST'
  form.action =
    'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
  form.setAttribute('target', customHiddenIframeName)
  for (const fieldName in fields) {
    const theInput = document.createElement('input')
    theInput.name = fieldName
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    theInput.value = fields[fieldName]
    theInput.setAttribute('type', 'hidden')
    form.appendChild(theInput)
  }
  document.body.appendChild(form)

  form.submit()
}
